<template>
  <b-row>
    <b-col cols="12">
      <b-card>
        <b-card-header
          class="justify-content-start p-0 mx-0 pb-1 mb-1 border-bottom"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$router.back()"
          >
            <feather-icon
              class="mr-12"
              icon="ChevronLeftIcon"
            />
          </b-button>
          <h3 class="mb-0 ml-2 d-flex justify-space-between">
            <span>Skill Development Program</span>
          </h3>
        </b-card-header>

        <b-container>

          <b-row>
            <!-- Organisation Selection -->
            <b-col
              cols="12"
              class="m-0 p-0"
            >
              <b-form-group
                label="Select Organisation"
                label-class="h5"
              >
                <v-select
                  id="selectStartup"
                  v-model="program.organisation_id"
                  :get-option-label="option => option.users_organizationtable.title"
                  :options="userOrgs"
                  :reduce="org => org.organization_id"
                  placeholder="Choose one from the list"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <hr></b-col>
            <!-- Overlay if Organisation not selected -->
            <b-overlay
              :show="!program.organisation_id"
              rounded="sm"
            >
              <template #overlay>
                <b-card
                  bg-variant="primary"
                  text-variant="light"
                >
                  Please Select Organisation
                </b-card>
              </template>
              <validation-observer
                ref="programForm"
              >
                <!-- Form Fields -->
                <b-row>
                  <b-col cols="12">
                    <h5 class="mb-2">
                      Basic Information
                    </h5>
                  </b-col>

                  <!-- Program Type -->
                  <b-col md="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Program Type"
                      rules="required"
                    >
                      <b-form-group
                        label="Program Type"
                        label-for="programType"
                        :state="errors.length ? false : null"
                      >
                        <v-select
                          id="programType"
                          v-model="program.type"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="programTypes"
                          label="Program Type"
                          placeholder="Program Type"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <!-- Program Name -->
                  <b-col md="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Program Name"
                      rules="required"
                    >
                      <b-form-group
                        label="Program Name"
                        label-for="programTitle"
                        :state="errors.length ? false : null"
                      >
                        <b-form-input
                          id="programTitle"
                          v-model="program.title"
                          placeholder="Program Name"
                          type="text"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <!-- Program Capacity -->
                  <b-col md="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Startup Capacity"
                      rules="required"
                    >
                      <b-form-group
                        label="Startup Capacity"
                        label-for="programCapacity"
                        :state="errors.length ? false : null"
                      >
                        <b-form-input
                          id="programCapacity"
                          v-model="program.capacity"
                          placeholder="Number of participants"
                          type="number"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <!-- Program Description -->
                  <b-col md="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Program Description"
                      rules="required"
                    >
                      <b-form-group
                        label="Program Description"
                        label-for="programDescription"
                        :state="errors.length ? false : null"
                      >
                        <b-form-input
                          id="programDescription"
                          v-model="program.description"
                          placeholder="Program Description"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <!-- Program Poster -->
                  <b-col cols="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="File"
                      rules="size:10000"
                    >
                      <b-form-group
                        label="Upload Program Poster (Image)"
                        label-for="programPoster"
                        :state="errors.length ? false : null"
                      >
                        <b-form-file
                          v-model="program.file"
                          accept="image/jpeg, image/png, image/gif"
                          drop-placeholder="Drop file here..."
                          placeholder="Choose a file or drop it here..."
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <!-- Program Duration -->
                  <b-col md="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Program Duration"
                    >
                      <b-form-group
                        label="Estimated Program Duration"
                        label-for="programDuration"
                        :state="errors.length ? false : null"
                      >
                        <b-form-input
                          id="programDuration"
                          v-model="program.duration"
                          placeholder="Eg: 6 Months or 2 Weeks or 3 Days"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col cols="12">
                    <hr></b-col>

                  <!-- Criteria Section -->
                  <b-col cols="12">
                    <h5 class="mb-0">
                      Criteria
                    </h5>
                  </b-col>
                  <b-col md="12">
                    <b-row
                      v-for="(item, i) in program.criteria"
                      :key="i"
                      class="mt-2"
                    >
                      <b-col>
                        <b-form-group
                          :label="`Criteria ${i + 1}`"
                          :label-for="`Criteria-${i + 1}`"
                        >
                          <validation-provider
                            v-slot="{ errors }"
                            :name="`Criteria ${i + 1}`"
                          >
                            <b-form-input
                              :id="`Criteria-${i + 1}`"
                              v-model="item.criteria"
                              placeholder="Criteria Title"
                              type="text"
                              :state="errors.length ? false : null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col sm="auto">
                        <!-- Remove Button -->
                        <b-button
                          v-if="program.criteria.length > 1"
                          class="mt-2 mr-2"
                          variant="outline-danger"
                          @click="removeCriteria(i)"
                        >
                          <feather-icon icon="XIcon" />
                        </b-button>
                        <!-- Add Button -->
                        <b-button
                          v-if="i === program.criteria.length - 1"
                          class="mt-2"
                          variant="outline-primary"
                          @click="addCriteria"
                        >
                          <feather-icon
                            icon="PlusIcon"
                            class="mr-25"
                          />
                          <span>Add Criteria</span>
                        </b-button>
                      </b-col>
                      <b-col cols="12">
                        <validation-provider
                          v-slot="{ errors }"
                          :name="`Criteria ${i + 1} Description`"
                        >
                          <b-form-input
                            :id="`description-${i + 1}`"
                            v-model="item.description"
                            placeholder="Criteria Description"
                            type="text"
                            :state="errors.length ? false : null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-col>
                    </b-row>
                  </b-col>

                  <b-col cols="12">
                    <hr></b-col>

                  <!-- Deliverables Section -->
                  <b-col cols="12">
                    <h5 class="mb-0">
                      Deliverables
                    </h5>
                  </b-col>
                  <b-col md="12">
                    <b-row
                      v-for="(item, i) in program.deliverables"
                      :key="i"
                      class="mt-2"
                    >
                      <b-col>
                        <b-form-group
                          :label="`Deliverable ${i + 1}`"
                          :label-for="`Deliverable-${i + 1}`"
                        >
                          <validation-provider
                            v-slot="{ errors }"
                            :name="`Deliverable ${i + 1}`"
                          >
                            <b-form-input
                              :id="`Deliverable-${i + 1}`"
                              v-model="item.deliverable"
                              placeholder="Deliverable Title"
                              type="text"
                              :state="errors.length ? false : null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col sm="auto">
                        <!-- Remove Button -->
                        <b-button
                          v-if="program.deliverables.length > 1"
                          class="mt-2 mr-2"
                          variant="outline-danger"
                          @click="removeDeliverable(i)"
                        >
                          <feather-icon icon="XIcon" />
                        </b-button>
                        <!-- Add Button -->
                        <b-button
                          v-if="i === program.deliverables.length - 1"
                          class="mt-2"
                          variant="outline-primary"
                          @click="addDeliverable"
                        >
                          <feather-icon
                            icon="PlusIcon"
                            class="mr-25"
                          />
                          <span>Add Deliverable</span>
                        </b-button>
                      </b-col>
                      <b-col cols="12">
                        <validation-provider
                          v-slot="{ errors }"
                          :name="`Deliverable ${i + 1} Description`"
                        >
                          <b-form-input
                            :id="`description-${i + 1}`"
                            v-model="item.description"
                            placeholder="Deliverable Description"
                            type="text"
                            :state="errors.length ? false : null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-col>

                    </b-row>
                  </b-col>
                </b-row>
              </validation-observer>
              <b-row>

                <b-col
                  cols="12"
                  class="text-right"
                ><hr>
                  <b-button
                    variant="primary"
                    class="px-2 py-75"
                    @click="addProgram"
                  >
                    Submit
                    <feather-icon
                      icon="ChevronRightIcon"
                      class="ml-25"
                    />
                  </b-button>
                </b-col>
              </b-row>
            </b-overlay>

          </b-row>
        </b-container>

      </b-card>

    </b-col>
  </b-row>
</template>

<script>
import {
  BButton,
  BCard,
  // BCardText,
  BCol,
  BFormGroup,
  BFormFile,
  BFormInput,
  BRow, BCardHeader,
  // BTab,
  // BTabs,
} from 'bootstrap-vue'
// import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { email, required } from '@core/utils/validations/validations'
import gql from 'graphql-tag'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
// import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
// import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import { getUserData } from '@/auth/utils'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

export default {
  components: {
    BCardHeader,
    ValidationProvider,
    ValidationObserver,
    // FormWizard,
    // TabContent,
    // AppTimeline,
    // AppTimelineItem,
    // BTabs,
    BFormFile,
    // BTab,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BButton,
    // BCardText,

    BCard,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      program: {
        organisation_id: null,
        programId: null,
        title: null,
        type: null,
        file: null,
        duration: null,
        description: null,
        capacity: null,
        lifeCycleStage: [],
        industry: null,
        fundraisingDisable: false,
        fundraising: [],
        fundRaisingSize: ['', ''],
        inHouseFunds: null,
        inHouseFundsSize: null,
        criteria: [
          {
            criteria: null,
            description: null,
          },
        ],
        deliverables: [
          {
            deliverable: null,
            description: null,
          },
        ],
        phases: [
          {
            title: null,
            description: null,
            objectives: [
              {
                title: null,
                description: null,
              },
            ],
            subphases: [{
              title: null,
              description: null,
              days: [
                {
                  activities: [
                    {
                      title: null,
                      operations_type: null,
                      duration: null,
                      description: null,
                      objectives: [
                        {
                          title: null,
                          description: null,
                        },
                      ],
                    },
                  ],
                },
              ],
            }],
          },
        ],
      },
      required,
      email,
      phasesTab: null,
      programTypes: ['Pre-incubation Program', 'Incubation Program', 'Accelerator Program'],
      industries: ['Agnostic',
        'Agriculture and Allied Industries',
        'Automobiles',
        'Auto Components',
        'Aviation',
        'Banking',
        'Biotechnology',
        'Cement',
        'Chemicals',
        'Consumer Durables',
        'Defence Manufacturing',
        'E-Commerce',
        'Education and Training',
        'Electronics System Design and Manufacturing',
        'Engineering and Capital Goods',
        'Financial Services',
        'FMCG',
        'Gems and Jewellery',
        'Healthcare',
        'Infrastructure',
        'Insurance',
        'IT and BPM',
        'Manufacturing',
        'Media and Entertainment',
        'Medical Devices',
        'Metals and Mining',
        'MSME',
        'Oil and Gas',
        'Pharmaceuticals',
        'Ports',
        'Power',
        'Railways',
        'Real Estate',
        'Renewable Energy',
        'Retail',
        'Roads',
        'Science and Technology',
        'Services',
        'Steel',
        'Telecommunications',
        'Textiles',
        'Tourism and Hospitality'],
    }
  },
  computed: {
    userOrgs() {
      const arr = getUserData()
        .associatedOrgDetails
      if (!arr.length) return []
      const newarr = arr
        .filter(obj => obj.role === 'superadmin')
        .filter(obj => obj) // Remove nulls
      this.handleselection(newarr)
      return newarr
    },
  },
  methods: {
    /* eslint-disable no-param-reassign */
    handleselection(getarr) {
      if (getarr.length === 1) {
        this.program.organisation_id = getarr[0].organization_id
      }
    },
    formSubmitted() {
      this.validationProgramForm()
        .then(() => {
          this.mutationLoading = true
          if (!this.$route.params.id) {
            this.addProgram()
          }
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Please fill all required fields',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    addProgram() {
      this.$apollo.mutate({
        // Query
        mutation: gql`mutation (
            $criteriaInput: [programs_shortlistingcriteriontable_insert_input!]!,
            $deliverableInput: [programs_deliverablelisttable_insert_input!]!
            ) {
            insert_programs_basicinfo_one(object: {
            status: "draft",
            is_skilldev: true,
            organization_id_id: ${this.program.organisation_id},
            title: "${this.program.title}",
            type: "${this.program.type}",
            description: "${this.program.description}",
            duration: "${this.program.duration}",
            capacity: "${this.program.capacity}",
            programs_shortlistingcriteriontables: {data: $criteriaInput},
            programs_deliverablelisttables: {data: $deliverableInput}})
            {
                id
            }
        }`,
        variables: {
          criteriaInput: this.program.criteria.filter(criteria => criteria.criteria),
          deliverableInput: this.program.deliverables.filter(deliverable => deliverable.deliverable),
        },
        update: (store, { data: { insert_programs_basicinfo_one } }) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: insert_programs_basicinfo_one.id ? 'Program created.' : 'Failed to create program',
              icon: insert_programs_basicinfo_one.id ? 'CheckIcon' : 'XIcon',
              variant: insert_programs_basicinfo_one.id ? 'info' : 'danger',
            },
          })
          if (insert_programs_basicinfo_one.id) {
            // Program Created, Create Phases
            // this.addPhases(insert_programs_basicinfo_one.id)
            this.$router.push({ name: 'manage-skill-dev-programs' })
          } else {
            // Error Occurred, stop loading
            this.mutationLoading = false
          }
        },
      })
      return this.error
    },
    validationProgramForm() {
      return new Promise((resolve, reject) => {
        this.$refs.programForm.validate()
          .then(success => {
            if (success) {
              resolve(true)
            } else {
              reject()
            }
          })
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
