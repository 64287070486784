var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-card',[_c('b-card-header',{staticClass:"justify-content-start p-0 mx-0 pb-1 mb-1 border-bottom"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"outline-secondary"},on:{"click":function($event){return _vm.$router.back()}}},[_c('feather-icon',{staticClass:"mr-12",attrs:{"icon":"ChevronLeftIcon"}})],1),_c('h3',{staticClass:"mb-0 ml-2 d-flex justify-space-between"},[_c('span',[_vm._v("Skill Development Program")])])],1),_c('b-container',[_c('b-row',[_c('b-col',{staticClass:"m-0 p-0",attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Select Organisation","label-class":"h5"}},[_c('v-select',{attrs:{"id":"selectStartup","get-option-label":function (option) { return option.users_organizationtable.title; },"options":_vm.userOrgs,"reduce":function (org) { return org.organization_id; },"placeholder":"Choose one from the list"},model:{value:(_vm.program.organisation_id),callback:function ($$v) {_vm.$set(_vm.program, "organisation_id", $$v)},expression:"program.organisation_id"}})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('hr')]),_c('b-overlay',{attrs:{"show":!_vm.program.organisation_id,"rounded":"sm"},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('b-card',{attrs:{"bg-variant":"primary","text-variant":"light"}},[_vm._v(" Please Select Organisation ")])]},proxy:true}])},[_c('validation-observer',{ref:"programForm"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-2"},[_vm._v(" Basic Information ")])]),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Program Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Program Type","label-for":"programType","state":errors.length ? false : null}},[_c('v-select',{attrs:{"id":"programType","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.programTypes,"label":"Program Type","placeholder":"Program Type"},model:{value:(_vm.program.type),callback:function ($$v) {_vm.$set(_vm.program, "type", $$v)},expression:"program.type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Program Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Program Name","label-for":"programTitle","state":errors.length ? false : null}},[_c('b-form-input',{attrs:{"id":"programTitle","placeholder":"Program Name","type":"text"},model:{value:(_vm.program.title),callback:function ($$v) {_vm.$set(_vm.program, "title", $$v)},expression:"program.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Startup Capacity","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Startup Capacity","label-for":"programCapacity","state":errors.length ? false : null}},[_c('b-form-input',{attrs:{"id":"programCapacity","placeholder":"Number of participants","type":"number"},model:{value:(_vm.program.capacity),callback:function ($$v) {_vm.$set(_vm.program, "capacity", $$v)},expression:"program.capacity"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Program Description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Program Description","label-for":"programDescription","state":errors.length ? false : null}},[_c('b-form-input',{attrs:{"id":"programDescription","placeholder":"Program Description"},model:{value:(_vm.program.description),callback:function ($$v) {_vm.$set(_vm.program, "description", $$v)},expression:"program.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"name":"File","rules":"size:10000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Upload Program Poster (Image)","label-for":"programPoster","state":errors.length ? false : null}},[_c('b-form-file',{attrs:{"accept":"image/jpeg, image/png, image/gif","drop-placeholder":"Drop file here...","placeholder":"Choose a file or drop it here..."},model:{value:(_vm.program.file),callback:function ($$v) {_vm.$set(_vm.program, "file", $$v)},expression:"program.file"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Program Duration"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Estimated Program Duration","label-for":"programDuration","state":errors.length ? false : null}},[_c('b-form-input',{attrs:{"id":"programDuration","placeholder":"Eg: 6 Months or 2 Weeks or 3 Days"},model:{value:(_vm.program.duration),callback:function ($$v) {_vm.$set(_vm.program, "duration", $$v)},expression:"program.duration"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('hr')]),_c('b-col',{attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Criteria ")])]),_c('b-col',{attrs:{"md":"12"}},_vm._l((_vm.program.criteria),function(item,i){return _c('b-row',{key:i,staticClass:"mt-2"},[_c('b-col',[_c('b-form-group',{attrs:{"label":("Criteria " + (i + 1)),"label-for":("Criteria-" + (i + 1))}},[_c('validation-provider',{attrs:{"name":("Criteria " + (i + 1))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":("Criteria-" + (i + 1)),"placeholder":"Criteria Title","type":"text","state":errors.length ? false : null},model:{value:(item.criteria),callback:function ($$v) {_vm.$set(item, "criteria", $$v)},expression:"item.criteria"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"sm":"auto"}},[(_vm.program.criteria.length > 1)?_c('b-button',{staticClass:"mt-2 mr-2",attrs:{"variant":"outline-danger"},on:{"click":function($event){return _vm.removeCriteria(i)}}},[_c('feather-icon',{attrs:{"icon":"XIcon"}})],1):_vm._e(),(i === _vm.program.criteria.length - 1)?_c('b-button',{staticClass:"mt-2",attrs:{"variant":"outline-primary"},on:{"click":_vm.addCriteria}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"PlusIcon"}}),_c('span',[_vm._v("Add Criteria")])],1):_vm._e()],1),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":("Criteria " + (i + 1) + " Description")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":("description-" + (i + 1)),"placeholder":"Criteria Description","type":"text","state":errors.length ? false : null},model:{value:(item.description),callback:function ($$v) {_vm.$set(item, "description", $$v)},expression:"item.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)}),1),_c('b-col',{attrs:{"cols":"12"}},[_c('hr')]),_c('b-col',{attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Deliverables ")])]),_c('b-col',{attrs:{"md":"12"}},_vm._l((_vm.program.deliverables),function(item,i){return _c('b-row',{key:i,staticClass:"mt-2"},[_c('b-col',[_c('b-form-group',{attrs:{"label":("Deliverable " + (i + 1)),"label-for":("Deliverable-" + (i + 1))}},[_c('validation-provider',{attrs:{"name":("Deliverable " + (i + 1))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":("Deliverable-" + (i + 1)),"placeholder":"Deliverable Title","type":"text","state":errors.length ? false : null},model:{value:(item.deliverable),callback:function ($$v) {_vm.$set(item, "deliverable", $$v)},expression:"item.deliverable"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"sm":"auto"}},[(_vm.program.deliverables.length > 1)?_c('b-button',{staticClass:"mt-2 mr-2",attrs:{"variant":"outline-danger"},on:{"click":function($event){return _vm.removeDeliverable(i)}}},[_c('feather-icon',{attrs:{"icon":"XIcon"}})],1):_vm._e(),(i === _vm.program.deliverables.length - 1)?_c('b-button',{staticClass:"mt-2",attrs:{"variant":"outline-primary"},on:{"click":_vm.addDeliverable}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"PlusIcon"}}),_c('span',[_vm._v("Add Deliverable")])],1):_vm._e()],1),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":("Deliverable " + (i + 1) + " Description")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":("description-" + (i + 1)),"placeholder":"Deliverable Description","type":"text","state":errors.length ? false : null},model:{value:(item.description),callback:function ($$v) {_vm.$set(item, "description", $$v)},expression:"item.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)}),1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"text-right",attrs:{"cols":"12"}},[_c('hr'),_c('b-button',{staticClass:"px-2 py-75",attrs:{"variant":"primary"},on:{"click":_vm.addProgram}},[_vm._v(" Submit "),_c('feather-icon',{staticClass:"ml-25",attrs:{"icon":"ChevronRightIcon"}})],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }